import { Circle, Icon } from 'component-library/components/icons';
import { IconName } from 'component-library/components/icons/Icon';
import { DropdownMenu } from 'component-library/components/navigation/mobile/DropdownMenu';
import {
  Header,
  Wrapper,
} from 'component-library/components/navigation/mobile/MobileNav';
import { Screen } from 'component-library/components/wrappers/ShowOn';
import { colors } from 'component-library/styles/colors';
import { StaticImage } from 'gatsby-plugin-image';
import React, { FC, useState } from 'react';
import { getSelectQuotePhoneNumber } from './utils';
import { HeadingProps } from '.';

interface MobileHeadingProps extends HeadingProps {}

export const MobileHeading: FC<MobileHeadingProps> = ({ phoneNumberOverride }) => {
  const [showMenu, setShowMenu] = useState(false);
  
  return (
    <Wrapper screens={[Screen.Sm, Screen.Md, Screen.Lg]}>
      <Header alignItems="center" justifyContent="space-between">
        <StaticImage
          src="../images/select-quote-retirable-logos.webp"
          alt="partnership logo"
          width={300}
        />
        <Circle onClick={() => setShowMenu((prev) => !prev)}>
          <Icon
            name={showMenu ? IconName.Xmark : IconName.Bars}
            color={colors.white}
          />
        </Circle>
      </Header>
      {showMenu && <DropdownMenu phoneNumber={phoneNumberOverride || getSelectQuotePhoneNumber()} />}
    </Wrapper>
  );
};
