import { getUrlTrackingDetails } from "helpers/getUtmDataFromUrl";

export const AUDIENCE_PHONE_NUMBERS: {[key: string]: string} = {
  sold: '18882030047',
  unsold: '18882049406'
}

const BASE_SELECT_QUOTE_PHONE_NUMBER = '18888461298';

export const getSelectQuotePhoneNumber = () => {
  const { term } = getUrlTrackingDetails(location.search);

  return term && AUDIENCE_PHONE_NUMBERS[term] || BASE_SELECT_QUOTE_PHONE_NUMBER;
};