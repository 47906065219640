export interface UtmData {
  source?: string;
  medium?: string;
  campaign?: string;
  content?: string;
  term?: string;
  gclid?: string;
  msclkid?: string;
}

const baseUtmParser = (
  urlSearchString: string,
  utmType:
    | 'utm_source'
    | 'utm_medium'
    | 'utm_campaign'
    | 'utm_term'
    | 'utm_content',
) => {
  const searchParams = new URLSearchParams(urlSearchString);
  if (searchParams && searchParams.has(utmType)) {
    return searchParams.get(utmType);
  } else {
    return null;
  }
};

const baseSearchEngineTrackingParser = (
  urlSearchString: string,
  searchEngineCode: 'msclkid' | 'gclid',
) => {
  const searchParams = new URLSearchParams(urlSearchString);
  if (searchParams && searchParams.has(searchEngineCode)) {
    return searchParams.get(searchEngineCode);
  } else {
    return null;
  }
};

const getUtmSourceFromUrl = (urlSearchString: string) =>
  baseUtmParser(urlSearchString, 'utm_source');

const getUtmMediumFromUrl = (urlSearchString: string) =>
  baseUtmParser(urlSearchString, 'utm_medium');

const getUtmCampaignFromUrl = (urlSearchString: string) =>
  baseUtmParser(urlSearchString, 'utm_campaign');

const getUtmTermFromUrl = (urlSearchString: string) =>
  baseUtmParser(urlSearchString, 'utm_term');

const getUtmContentFromUrl = (urlSearchString: string) =>
  baseUtmParser(urlSearchString, 'utm_content');

const getBingTrackingCodeFromUrl = (urlSearchString: string) =>
  baseSearchEngineTrackingParser(urlSearchString, 'msclkid');

const getGoogleTrackingCodeFromUrl = (urlSearchString: string) =>
  baseSearchEngineTrackingParser(urlSearchString, 'gclid');

export const getUrlTrackingDetails = (urlSearchString: string) => ({
  source: getUtmSourceFromUrl(urlSearchString),
  medium: getUtmMediumFromUrl(urlSearchString),
  campaign: getUtmCampaignFromUrl(urlSearchString),
  content: getUtmContentFromUrl(urlSearchString),
  term: getUtmTermFromUrl(urlSearchString),
  gclid: getGoogleTrackingCodeFromUrl(urlSearchString),
  msclkid: getBingTrackingCodeFromUrl(urlSearchString),
});
