import Layout from 'common/layouts/Layout';
import { TrustPilot } from 'common/trustpilot/TrustPilot';
import { PageWrapper } from 'component-library/components/wrappers/PageWrapper';
import { SEO } from 'components/SEO';
import { ConsultationModal } from 'page-components/partnerships/deprecated/selectquote/consultation-form/ConsultationModal';
import { Heading } from 'page-components/partnerships/deprecated/selectquote/heading';
import { Footer, FooterTemplate } from 'page-components/shared/footer';
import React, { FC } from 'react';
import { SelectQuoteProps } from './ny';
import { HeroWithForm } from 'page-components/partnerships/deprecated/selectquote/hero-with-form';
import { Featured } from 'page-components/shared';
import { SpendSaveProtectPlan } from 'page-components/partnerships/deprecated/selectquote/spend-save-protect-plan';
import { BottomCta } from 'page-components/partnerships/deprecated/selectquote/bottom-cta';
import { IncomeLasts } from 'page-components/partnerships/deprecated/selectquote/income-lasts';
import { AUDIENCE_PHONE_NUMBERS } from 'page-components/partnerships/deprecated/selectquote/heading/utils';
import { promoterVerificationComplianceFooterFor } from 'page-components/partnerships/shared/content';

const SelectQuote: FC<SelectQuoteProps> = () => {
  const [isModalOpen, setIsModalOpen] = React.useState(false);

  const handleConsultationClick = () => {
    setIsModalOpen(true);
  };

  const handleConsultationClose = () => {
    setIsModalOpen(false);
  };

  const childProps = {
    onConsultationClick: handleConsultationClick,
  };

  return (
    <Layout>
      <Heading {...childProps} phoneNumberOverride={AUDIENCE_PHONE_NUMBERS.sold} />
      <PageWrapper
        background="linear-gradient(
          180deg,
          #EBF8F2 33%,
          #ffe8d8 90%,
          #FFCFAD 100%
        )"
      >
        {isModalOpen && <ConsultationModal onClose={handleConsultationClose} />}
        <HeroWithForm {...childProps} />
        <Featured />
        <SpendSaveProtectPlan />
        <IncomeLasts {...childProps} />
        <TrustPilot
          template="fullSizeCarousel"
          heading="We've helped more than 50,000 people just like you."
        />
        <BottomCta
          heading="Get your questions answered by the experts today!"
          body="Talk to a licensed Retirable Advisor today!"
        />
        <Footer
          template={FooterTemplate.LegalDisclaimerAndContacts}
          additionalFootnotes={[
            promoterVerificationComplianceFooterFor('SelectQuote')
          ]}
          {...childProps}
        />
      </PageWrapper>
    </Layout>
  );
};

export default SelectQuote;

export const Head = () => (
  <SEO
    title="SelectQuote + Retirable"
    description="Learn more about how SelectQuote and Retirable are working together to help you have peace of mind in retirement."
  />
);
