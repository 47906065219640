import React from 'react';

export const BottomCtaPattern = ({ ...props }) => (
  <svg
    width="480"
    height="320"
    viewBox="0 0 480 320"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M400.002 160H320.002V240H400.002V160Z" fill="#FADAC5" />
    <path d="M480 0H400V80H480V0Z" fill="#FADAC5" />
    <path d="M480.002 240H400.002V320H480.002V240Z" fill="#FADAC5" />
    <path d="M320.002 240H240.002V320H320.002V240Z" fill="#EAC0A2" />
    <path d="M160.002 240H80.0017V320H160.002V240Z" fill="#FEE5D6" />
    <path
      d="M480.002 240V160C435.843 160 400.031 195.825 400.002 240H480.002Z"
      fill="#EAC0A2"
    />
    <path
      d="M240.002 320V240C195.843 240 160.031 275.825 160.002 320H240.002Z"
      fill="#FADBC5"
    />
    <path
      d="M-0.000251134 240L-0.000244141 320C44.1588 320 79.9706 284.175 79.9997 240L-0.000251134 240Z"
      fill="#FADBC5"
    />
    <path
      d="M400.002 80H320.002C320.002 124.159 355.827 159.971 400.002 160V80Z"
      fill="#FEE5D6"
    />
    <path
      d="M400.002 320L400.002 240C355.843 240 320.031 275.825 320.002 320L400.002 320Z"
      fill="#FEE5D6"
    />
    <path
      d="M240.002 160L240.002 240C284.161 240 319.973 204.175 320.002 160L240.002 160Z"
      fill="#FEE5D6"
    />
    <path
      d="M160 240L160 160C115.841 160 80.0289 195.825 79.9998 240L160 240Z"
      fill="#EAC0A2"
    />
  </svg>
);
