import { ButtonColor, PrimaryButton } from 'component-library/components/buttons/PrimaryButton';
import { Flex } from 'component-library/components/layout/Flex';
import { Body } from 'component-library/components/typography/Body';
import { Heading } from 'component-library/components/typography/Heading';
import { colors } from 'component-library/styles/colors';
import { device } from 'component-library/styles/device';
import { SelectQuoteChildProps } from 'pages/partners/selectquote/ny';
import React, { FC, useState } from 'react';
import styled from 'styled-components';
import { track } from 'utils/analytics';

export interface IncomeLastsProps extends SelectQuoteChildProps {}

const Wrapper = styled(Flex)`
    margin: 16px;
    padding: 16px;
    background-color: ${colors.white};
    border-radius: 32px;
    border-top: 1px solid ${colors.black};
    border-right: 1px solid ${colors.black};
    border-bottom: 3px solid ${colors.black};
    border-left: 3px solid ${colors.black};

    @media ${device.lg} {
        max-width: 1000px;
        margin: 40px auto;
        padding: 40px 0;
    }
`;

const StyledHeading = styled(Heading)`
    text-align: center;
`;

const SubmitButton = styled(PrimaryButton)`
    margin-top: 32px;
    max-width: 420px;
`;

interface WordSelectorProps {
    options: string[];
    value: string;
    onChange: (value: string) => void;
}

const ageOptions = ['', '<60', '61', '62', '63', '64', '65', '>65'];
const timeToRetirementOptions = ['', 'Already retired', '<1 year', '1-3 years', '3-5 years', '>5 years'];
const maritalStatusOptions = ['', 'Married', 'Single', 'Divorced', 'Widowed', 'Other'];
const savingsOptions = ['', '<$50k', '$50k-$199k', '$200k-$500k', '$500k-$999k', '>$1M'];

const StyledSelect = styled.select`
    -moz-appearance: none; 
    -webkit-appearance: none; 
    appearance: none;
    border-radius: 0;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    background: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='black'><polygon points='0,0 100,0 50,50'/></svg>") no-repeat;
    background-size: 12px;
    background-position: right;
    border-bottom: 1px solid ${colors.black};
    padding-right: 16px;
`;

const WordSelector: FC<WordSelectorProps> = ({ options, value, onChange }) => (
    <StyledSelect value={value} onChange={(e) => onChange(e.target.value)}>
        {options.map((option) => (
            <option key={option} value={option}>
                {option}
            </option>
        ))}
    </StyledSelect>
);

export const IncomeLasts: FC<IncomeLastsProps> = ({ onConsultationClick }) => {
    const [age, setAge] = useState('');
    const [timeToRetirement, setTimeToRetirement] = useState('');
    const [maritalStatus, setMaritalStatus] = useState('');
    const [savings, setSavings] = useState('');

    const handleSubmit = () => {
        track('Income Lasts Form Submitted', { age, timeToRetirement, maritalStatus, savings });
        onConsultationClick();
    };

    return (
        <Wrapper column alignItems="center" justifyContent="flex-start">
            <StyledHeading variant={4}>
                How long will your income last in retirement?
            </StyledHeading>
            <Body variant={3}>
                Tell us about yourself and we'll help you enjoy a more confident retirement!
            </Body>
            <Flex column marginTop={40}>
                <Body variant={1} marginBottom={16}>
                    I am <WordSelector value={age} options={ageOptions} onChange={setAge} /> years old and want to retire in <WordSelector value={timeToRetirement} options={timeToRetirementOptions} onChange={setTimeToRetirement} />.
                </Body>
                <Body variant={1}>
                    I am <WordSelector value={maritalStatus} options={maritalStatusOptions} onChange={setMaritalStatus} /> and have saved <WordSelector value={savings} options={savingsOptions} onChange={setSavings} /> for retirement.
                </Body>
            </Flex>
            <SubmitButton
              fullWidth
              buttonColor={ButtonColor.Teal}
              alignment="center"
              onClick={handleSubmit}
            >
              Start my FREE Retirement Plan
            </SubmitButton>
        </Wrapper>
    );
}
