import {
  ButtonColor,
  PrimaryButton,
} from 'component-library/components/buttons/PrimaryButton';
import {
  Header,
  InnerWrapper,
} from 'component-library/components/navigation/desktop/DesktopNav';
import { Screen } from 'component-library/components/wrappers/ShowOn';
import { StaticImage } from 'gatsby-plugin-image';
import { SelectQuoteChildProps } from 'pages/partners/selectquote/ny';
import React, { FC } from 'react';
import styled from 'styled-components';
import { HeadingProps } from '.';

const StyledHeader = styled(Header)`
  z-index: 5;
`;

interface DesktopHeadingProps extends HeadingProps {}

export const DesktopHeading: FC<DesktopHeadingProps> = ({
  onConsultationClick,
}) => (
  <StyledHeader screens={[Screen.Xl, Screen.Xxl]}>
    <InnerWrapper alignItems="center" justifyContent="space-between">
      <StaticImage
        src="../images/select-quote-retirable-logos.webp"
        alt="partnership logo"
        width={300}
      />
      <PrimaryButton
        buttonColor={ButtonColor.Peach}
        onClick={onConsultationClick}
        alignment="center"
      >
        Get Started
      </PrimaryButton>
    </InnerWrapper>
  </StyledHeader>
);
