import { Grid } from 'common/layouts/grid/Grid';
import { Flex } from 'component-library/components/layout/Flex';
import { ResponsiveBody } from 'component-library/components/typography/ResponsiveBody';
import { ResponsiveHeading } from 'component-library/components/typography/ResponsiveHeading';
import { Screen, ShowOn } from 'component-library/components/wrappers/ShowOn';
import { colors } from 'component-library/styles/colors';
import React, { FC } from 'react';
import styled from 'styled-components';

import { body, heading } from './content';
import { Step } from './Step';
import { getCurrentInterestRateDetails } from 'common/interest-rate-components/base';
import { GetCurrentInterestRateDetailsFragment } from 'graphqlTypes';
import { StaticImage } from 'gatsby-plugin-image';
import { FOOTNOTE_REFERENCES } from 'page-components/shared/footer/utils';

const StepsWrapper = styled(Flex)`
  max-width: 1488px;
  margin: 40px auto;
  padding: 120px 200px;
  background-color: ${colors.forestGreen};
  border-radius: 32px;
`;

interface StepsLargeProps {
  data: GetCurrentInterestRateDetailsFragment
}

export const StepsLarge: FC<StepsLargeProps> = ({ data }) => {
  const { formattedApy } = getCurrentInterestRateDetails(data);

  return (
    <ShowOn screens={[Screen.Lg, Screen.Xl, Screen.Xxl]}>
      <StepsWrapper column>
        <ResponsiveHeading color="white" variant={2} marginBottom={32}>
          {heading}
        </ResponsiveHeading>
        <ResponsiveBody color="white" variant={1} marginBottom={80}>
          {body}
        </ResponsiveBody>
        <Grid cols={2} gap={6}>
          <Step
            heading="One-on-one retirement plan guidance and monitoring"
            body="Get a personalized retirement plan with the ongoing guidance and monitoring of a dedicated fiduciary advisor."
            image={<StaticImage src="../images/plan-step.webp" alt="plan" />}
          />
          <Step
            heading="Reliable retirement income with one easy, safe-to-spend paycheck"
            body="We'll review your savings, Social Security and other income sources, to help your personalized Safe-to-spend number each month."
            image={<StaticImage src="../images/spend-step.webp" alt="spend" />}
          />
          <Step
            heading={`High-yield deposit account earning ${formattedApy} APY* on your cash savings`}
            body="Savings and budgetary insights maximize your cash flows while monthly cash sweeps help you have more cash to spend in retirement."
            image={<StaticImage src="../images/save-step.webp" alt="save" />}
          />
          <Step
            heading="$1M in identity theft protection with identity and credit monitoring"
            body="$1 million in identity theft insurance — along with real human assistance, white-glove credit freezing, lost wallet assistance, and the ability to block spam calls."
            image={<StaticImage src="../images/protect-step.webp" alt="protect" />}
          />
        </Grid>
      </StepsWrapper>
    </ShowOn>
  );
}
