import React from 'react';

export const BottomCtaPattern3 = ({ ...rest }) => (
  <svg
    width="240"
    height="160"
    viewBox="0 0 240 160"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <path d="M160 80.0002H79.9999V160H160V80.0002Z" fill="#FEE5D6" />
    <path
      d="M79.9999 160V80C35.8409 80 0.0291254 115.825 -6.10352e-05 160H79.9999Z"
      fill="#FFCFAD"
    />
    <path
      d="M240 80L160 80C160 124.159 195.825 159.971 240 160L240 80Z"
      fill="#FADBC5"
    />
    <path
      d="M79.9999 0L79.9999 80C124.159 80 159.971 44.1752 160 -3.49691e-06L79.9999 0Z"
      fill="#FFCFAD"
    />
  </svg>
);
