import { Flex } from 'component-library/components/layout/Flex';
import { ResponsiveBody } from 'component-library/components/typography/ResponsiveBody';
import { ResponsiveHeading } from 'component-library/components/typography/ResponsiveHeading';
import { Screen, ShowOn } from 'component-library/components/wrappers/ShowOn';
import { colors } from 'component-library/styles/colors';
import React, { FC } from 'react';
import styled from 'styled-components';

import { BottomCtaProps } from '.';
import { VideoLarge } from './VideoLarge';
import { StyledFormLarge } from '../consultation-form/StyledFormLarge';
import { defaultInitialValues } from '../consultation-form/formUtils';
import { ButtonColor } from 'component-library/components/buttons/PrimaryButton';

const BottomCtaWrapper = styled(ShowOn)`
  max-width: 1200px;
  background-color: ${colors.peach};
  border-radius: 32px;
  margin: 40px auto;
  overflow: hidden;
  position: relative;
`;

const StyledHeading = styled(ResponsiveHeading)`
  text-align: center;
`;

const StyledBody = styled(ResponsiveBody)`
  max-width: 560px;
  text-align: center;
  font-weight: bold;
`;

const Text = styled(Flex)`
  max-width: 1000px;
  margin: 0 auto;
  padding: 60px 40px 120px;
  position: relative;
  z-index: 2;
`;

const ButtonWrapper = styled(Flex)`
  width: 100%;
  padding: 0 200px;
`;

export const BottomCtaLarge: FC<BottomCtaProps> = ({
  heading,
  body,
}) => (
  <BottomCtaWrapper screens={[Screen.Lg, Screen.Xl, Screen.Xxl]}>
    <Text column alignItems="center">
      <StyledHeading variant={3} marginBottom={24}>
        {heading}
      </StyledHeading>
      {body && (
        <StyledBody variant={1} marginBottom={56}>
          {body}
        </StyledBody>
      )}
      <StyledFormLarge
        inModal={false}
        submitButtonText="Get Started"
        initialValues={defaultInitialValues}
        submitButtonColor={ButtonColor.Teal}
      />
    </Text>
    <VideoLarge />
  </BottomCtaWrapper>
);
