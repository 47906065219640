export const makeMostOfRetirement = "We're here to help you make the most of your retirement.";

export const partneredWithRetirable = "America's #1 Term Life Sales Agency has partnered with Retirable to support your retirement";
export const regardlessOfIncome = 'regardless of your income level';

export const retirableHelpsYou = 'Retirable helps you:';
export const advisorBulletPoint = 'Make key financial decisions with the ongoing guidance of an advisor';
export const safeToSpendBulletPoint = "Know what's safe to spend each month";
export const interestBulletPoint = 'Earn monthly interest without limiting your spending';
export const protectionBulletPoint = 'Protect yourself from fraud and online scams';

export const talkToAdvisorToday = 'Talk to a licensed Retirable Advisor today!';

export const submitButtonText = 'Meet with a Retirable Advisor';