import { SelectQuoteChildProps } from 'pages/partners/selectquote/ny';
import React, { FC } from 'react';

import { DesktopHeading } from './DesktopHeading';
import { MobileHeading } from './MobileHeading';

export interface HeadingProps extends SelectQuoteChildProps {
  phoneNumberOverride?: string;
}

export const Heading: FC<HeadingProps> = (props) => (
  <>
    <DesktopHeading {...props} />
    <MobileHeading {...props} />
  </>
);
