import React, { FC } from 'react';

import { StepsLarge } from './StepsLarge';
import { StepsSmall } from './StepsSmall';
import { graphql, useStaticQuery } from 'gatsby';

interface SpendSaveProtectPlanProps {}

export const SpendSaveProtectPlan: FC<SpendSaveProtectPlanProps> = () => {
  const data = useStaticQuery(graphql`
    query SelectQuoteSpendSaveProtectPlan {
      ...GetCurrentInterestRateDetails
    }
  `);

  return (
    <>
      <StepsSmall data={data} />
      <StepsLarge data={data} />
    </>
  );
}
