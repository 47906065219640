import React from 'react';

export const BottomCtaPattern2 = ({ ...props }) => (
  <svg
    width="160"
    height="160"
    viewBox="0 0 160 160"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M160 80H79.9999V160H160V80Z" fill="#EAC0A2" />
    <path
      d="M79.9999 160L79.9999 80C35.8409 80 0.0291275 115.825 -6.45321e-05 160L79.9999 160Z"
      fill="#FEE5D6"
    />
    <path
      d="M79.9999 0L-6.10352e-05 0C-6.10352e-05 44.1591 35.8248 79.9708 79.9999 80L79.9999 0Z"
      fill="#FADBC5"
    />
  </svg>
);
