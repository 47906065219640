import React, { FC } from 'react';

import { BottomCtaLarge } from './BottomCtaLarge';
import { BottomCtaSmall } from './BottomCtaSmall';

export interface BottomCtaProps {
  heading: string;
  body?: string;
}

export const BottomCta: FC<BottomCtaProps> = (props) => (
  <>
    <BottomCtaSmall {...props} />
    <BottomCtaLarge {...props} />
  </>
);
