import { Flex, FlexProps } from 'component-library/components/layout/Flex';
import { Body } from 'component-library/components/typography/Body';
import { Subheading } from 'component-library/components/typography/Subheading';
import React, { FC } from 'react';
import styled from 'styled-components';

const ImageWrapper = styled.div`
  max-width: 400px;
`;

interface StepProps extends FlexProps {
  heading: string;
  body: string;
  image: React.ReactNode;
}

export const Step: FC<StepProps> = ({ heading, body, image, ...rest }) => {

  return (
    <Flex column {...rest}>
      <ImageWrapper>{image}</ImageWrapper>
      <Subheading color="white" variant={1} marginBottom={12} marginTop={40}>
        {heading}
      </Subheading>
      <Body color="white" variant={3}>
        {body}
      </Body>
    </Flex>
  );
};
