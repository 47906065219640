import { SelectQuoteChildProps } from 'pages/partners/selectquote/ny';
import React, { FC } from 'react';

import styled from 'styled-components';
import { Screen, ShowOn } from 'component-library/components/wrappers/ShowOn';
import { Grid } from 'common/layouts/grid/Grid';
import { GridColumn } from 'common/layouts/grid/GridColumn';
import { Heading } from 'component-library/components/typography/Heading';
import { Body } from 'component-library/components/typography/Body';
import { Flex } from 'component-library/components/layout/Flex';
import { StaticImage } from 'gatsby-plugin-image';
import { StyledCheckmark } from 'page-components/index/plan/shared';
import { StyledFormLarge } from '../consultation-form/StyledFormLarge';
import { defaultInitialValues } from '../consultation-form/formUtils';
import { StyledFormSmall } from '../consultation-form/StyledFormSmall';
import { advisorBulletPoint, interestBulletPoint, makeMostOfRetirement, partneredWithRetirable, protectionBulletPoint, regardlessOfIncome, retirableHelpsYou, safeToSpendBulletPoint, submitButtonText, talkToAdvisorToday } from './content';

interface HeroWithFormProps extends SelectQuoteChildProps {}

const DesktopWrapper = styled(ShowOn).attrs({
  screens: [Screen.Xxl, Screen.Xl],
})`
  max-width: 1488px;
  margin: 0 auto;
  padding: 32px;
`;

const MobileWrapper = styled(ShowOn).attrs({
  screens: [Screen.Sm, Screen.Md, Screen.Lg],
})``;

const MobileImageWrapper = styled(Flex)`
  padding: 0 20px;
`;

const MobileContentWrapper = styled(Flex)`
  padding: 0 20px;
`;

const BulletPoint = ({ text }: { text: string }) => (
  <Flex alignItems="baseline">
    <StyledCheckmark />
    <Body variant={1}>{text}</Body>
  </Flex>
);

export const HeroWithForm: FC<HeroWithFormProps> = (props) => (
  <>
    <DesktopWrapper>
      <Grid cols={8} gap={1} spacing={1}>
        <GridColumn span={4}>
          <Heading variant={4} marginBottom={24} marginTop={32}>
            {makeMostOfRetirement}
          </Heading>
          <Body variant={1}>
            {partneredWithRetirable}- <b>{regardlessOfIncome}</b>.
          </Body>
          <Flex marginTop={24}>
            <Body variant={1}>
              <b>{retirableHelpsYou}</b>
            </Body>
          </Flex>
          <Flex column marginTop={16} marginBottom={32}>
            <BulletPoint text={advisorBulletPoint} />
            <BulletPoint text={safeToSpendBulletPoint} />
            <BulletPoint text={interestBulletPoint} />
            <BulletPoint text={protectionBulletPoint} />
          </Flex>
          <Heading variant={5} marginBottom={24} alignment="center">
            {talkToAdvisorToday}
          </Heading>
          <StyledFormLarge
            inModal={false}
            submitButtonText={submitButtonText}
            initialValues={defaultInitialValues}
          />
        </GridColumn>
        <GridColumn span={4}>
          <StaticImage src="../images/three-generations.webp" alt="hero" />
        </GridColumn>
      </Grid>
    </DesktopWrapper>
    <MobileWrapper>
      <Flex column alignItems="center">
        <MobileImageWrapper>
          <StaticImage src="../images/three-generations.webp" alt="hero" />
        </MobileImageWrapper>
        <MobileContentWrapper column>
          <Heading variant={4} marginBottom={24} marginTop={32}>
            {makeMostOfRetirement}
          </Heading>
          <Body variant={1}>
            {partneredWithRetirable}- <b>{regardlessOfIncome}</b>.
          </Body>
          <Flex marginTop={24}>
            <Body variant={1}>
              <b>{retirableHelpsYou}</b>
            </Body>
          </Flex>
          <Flex column marginTop={16} marginBottom={32}>
            <BulletPoint text={advisorBulletPoint} />
            <BulletPoint text={safeToSpendBulletPoint} />
            <BulletPoint text={interestBulletPoint} />
            <BulletPoint text={protectionBulletPoint} />
          </Flex>
          <Heading variant={5} marginBottom={24} alignment="center">
            {talkToAdvisorToday}
          </Heading>
          <StyledFormSmall
            inModal={false}
            submitButtonText={submitButtonText}
            initialValues={defaultInitialValues}
          />
        </MobileContentWrapper>
      </Flex>
    </MobileWrapper>
  </>
);
