import { Flex } from 'component-library/components/layout/Flex';
import { Body } from 'component-library/components/typography/Body';
import { Heading } from 'component-library/components/typography/Heading';
import { Screen, ShowOn } from 'component-library/components/wrappers/ShowOn';
import { colors } from 'component-library/styles/colors';
import React, { FC } from 'react';
import styled from 'styled-components';

import { body, heading } from './content';
import { Step } from './Step';
import { StaticImage } from 'gatsby-plugin-image';
import { getCurrentInterestRateDetails } from 'common/interest-rate-components/base';
import { GetCurrentInterestRateDetailsFragment } from 'graphqlTypes';
import { FOOTNOTE_REFERENCES } from 'page-components/shared/footer/utils';

const StepsWrapper = styled(Flex)`
  max-width: 1140px;
  margin: auto;
  background-color: ${colors.forestGreen};
  padding: 80px 20px;
`;

interface StepsSmallProps {
  data: GetCurrentInterestRateDetailsFragment
}

export const StepsSmall: FC<StepsSmallProps> = ({ data }) => {
  const { formattedApy } = getCurrentInterestRateDetails(data);

  return (
    <ShowOn screens={[Screen.Sm, Screen.Md]}>
      <StepsWrapper column>
        <Heading color="white" variant={4} marginBottom={16}>
          {heading}
        </Heading>
        <Body color="white" variant={3} marginBottom={64}>
          {body}
        </Body>
        <Step
          heading="One-on-one retirement plan guidance and monitoring"
          body="Get a personalized retirement plan with the ongoing guidance and monitoring of a dedicated fiduciary advisor."
          image={<StaticImage src="../images/plan-step.webp" alt="plan" />}
          marginBottom={32}
        />
        <Step
          heading="Reliable retirement income with one easy, safe-to-spend paycheck"
          body="We'll review your savings, Social Security and other income sources, to help your personalized Safe-to-spend number each month."
          image={<StaticImage src="../images/spend-step.webp" alt="spend" />}
          marginBottom={32}
        />
        <Step
          heading={`High-yield deposit account earning ${formattedApy} APY${FOOTNOTE_REFERENCES.asterisk} on your cash savings`}
          body="Savings and budgetary insights maximize your cash flows while monthly cash sweeps help you have more cash to spend in retirement."
          image={<StaticImage src="../images/save-step.webp" alt="save" />}
          marginBottom={32}
        />
        <Step
          heading="$1M in identity theft protection with identity and credit monitoring"
          body="$1 million in identity theft insurance — along with real human assistance, white-glove credit freezing, lost wallet assistance, and the ability to block spam calls."
          image={<StaticImage src="../images/protect-step.webp" alt="protect" />}
          marginBottom={32}
        />
      </StepsWrapper>
    </ShowOn>
  );
}
