import { Body } from 'component-library/components/typography/Body';
import { Heading } from 'component-library/components/typography/Heading';
import { Screen, ShowOn } from 'component-library/components/wrappers/ShowOn';
import { colors } from 'component-library/styles/colors';
import React, { FC } from 'react';
import styled from 'styled-components';

import { BottomCtaProps } from '.';
import { VideoSmall } from './VideoSmall';
import { StyledFormSmall } from '../consultation-form/StyledFormSmall';
import { defaultInitialValues } from '../consultation-form/formUtils';
import { ButtonColor } from 'component-library/components/buttons/PrimaryButton';

const BottomCtaWrapper = styled(ShowOn)`
  background-color: ${colors.peach};
  border-radius: 32px;
  overflow: hidden;
  position: relative;
  margin: 40px auto;
`;

const TextAndImage = styled.div`
  padding: 64px 20px 0;
`;

const StyledHeading = styled(Heading)`
  text-align: center;
`;

const StyledBody = styled(Body)`
  text-align: center;
  font-weight: bold;
`;

export const BottomCtaSmall: FC<BottomCtaProps> = ({
  heading,
  body,
}) => (
  <BottomCtaWrapper screens={[Screen.Sm, Screen.Md]}>
    <TextAndImage>
      <StyledHeading variant={4} marginBottom={16}>
        {heading}
      </StyledHeading>
      {body && (
        <StyledBody variant={3} marginBottom={48}>
          {body}
        </StyledBody>
      )}
      <StyledFormSmall
        inModal={false}
        submitButtonText="Get Started"
        initialValues={defaultInitialValues}
        submitButtonColor={ButtonColor.Teal}
      />
    </TextAndImage>
    <VideoSmall />
  </BottomCtaWrapper>
);
